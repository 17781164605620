// scriptaculous compatibility hack:
// reliquish jQuery's control of the jQuery variable
jQuery.noConflict();

jQuery(document).ready(function($) {

    /**
     * HACK: mitigate prototype conflict with jquery causing dropdown menus / tabs to disappear on click
     * @see https://stackoverflow.com/questions/19139063/twitter-bootstrap-3-dropdown-menu-disappears-when-used-with-prototype-js
     */
    (function() {
        var isBootstrapEvent = false;
        if (window.jQuery) {
            var all = jQuery('*');
            jQuery.each(['hide.bs.dropdown',
                'hide.bs.collapse',
                'hide.bs.modal',
                'hide.bs.tooltip',
                'hide.bs.popover',
                'hide.bs.tab'], function(index, eventName) {
                all.on(eventName, function( event ) {
                    isBootstrapEvent = true;
                });
            });
        }
        var originalHide = Element.hide;
        Element.addMethods({
            hide: function(element) {
                if(isBootstrapEvent) {
                    isBootstrapEvent = false;
                    return element;
                }
                return originalHide(element);
            }
        });
    })();

    // setup bsClasses
    var screen = $('html').bsClasses();

    // setup fixed navbar
    $('#topnav').affixNavbar();

    // setup scrollup
    $.scrollUp({
        scrollText: '',
        // prevent from overlapping topnav
        zIndex: 999
    });

    // search mini form:
    // display spinner on submit button when form is submitted
    let searchForm = $('#search_mini_form');
    if (searchForm.length) {
        let searchFormField = $('#search', searchForm);
        if (searchFormField.length) {
            $(searchFormField).focus();
        }
        let searchFormBtnIcon = $('button > span.fa-search', searchForm);
        if (searchFormBtnIcon.length) {
            $(searchForm).on('submit', () => {
                $(searchFormBtnIcon).attr('class', 'fa fa-lg fa-spinner fa-spin');
            });
        }
    }

    // first-level topnav items:
    var toplevelItems = $('.navbar .level0 > li.dropdown');
    toplevelItems.each(function(i,dropdown) {
        $(dropdown)

            // toggle dropdown onmouseenter / onmouseleave for desktop screens
            .on('mouseenter mouseleave', function() {
                if (screen.isDesktop()) {
                    $(dropdown).children('[data-toggle="dropdown"]').trigger('click.bs.dropdown');
                }
            })

            // alter default dropdown onclick behaviour for desktop screens
            .on('click', function(event) {
                if (screen.isDesktop()) {
                    event.stopPropagation();
                }
            })
        ;
    });

    // This code will prevent unexpected menu close when using some components (like accordion, forms, etc)
    $(document).on('click', '.yamm .dropdown-menu', function(e) {
        e.stopPropagation()
    });

    // topnav dropdown items:
    var dropdownItems = $('.navbar [data-toggle="dropdown"]');
    dropdownItems.each(function(i, dropdown) {
        $(dropdown)

            // alter nested dropdown behaviour (@see http://www.bootply.com/GxVsayuMBv)
            .on('click.bs.dropdown', function(event) {

                var liContainer = $(event.currentTarget).parent();
                var ulContainer = liContainer.parent();

                // mobile menu:
                if (screen.isMobile()) {

                    // disable default dropdown menu behaviour
                    event.stopPropagation();

                    // toggle dropdown only if dropdown-toggle-icon was clicked
                    if ($(event.target).hasClass('dropdown-toggle-icon')) {
                        // Avoid following the href location of parent link when clicking
                        event.preventDefault();
                        // toggle dropdown menu manually
                        liContainer.toggleClass('open');
                    }

                    // default desktop menu:
                } else {

                    // Avoid dropdown toggle on subitems:
                    if (!ulContainer.hasClass('navbar-nav')) {
                        event.stopPropagation();
                    }
                }

            })
        ;
    });

    // dropdown effects
    // @see: http://stackoverflow.com/questions/12115833/adding-a-slide-effect-to-bootstrap-dropdown
    //// ADD SLIDEDOWN ANIMATION TO DROPDOWN //
    //$('.dropdown').on('show.bs.dropdown', function(e){
    //    $(this).find('.dropdown-menu').first().stop(true, true).fadeIn();
    //});
    //
    //// ADD SLIDEUP ANIMATION TO DROPDOWN //
    //$('.dropdown').on('hide.bs.dropdown', function(e){
    //    $(this).find('.dropdown-menu').first().stop(true, true).hide();
    //});

    // form submit
    $('form', '.main').on('submit', function(event) {
        // add loading icon to submit button
        var form = this;
        var btn = $('[type=submit]', form);
        if (!$('.fa', btn).length) {
            $(btn).append('<span class="fa fa-spinner fa-spin"></span>');
        } else {
            $('.fa', btn).addClass('fa-spinner fa-spin');
        }
        // disable button
        $(btn).attr('disabled', 'disabled');

        setTimeout(function() {
            // if form has errors (= error notices shown): remove loading icon again
            if ($('.validation-advice:visible', form).length) {
                $('.fa', btn).removeClass('fa-spinner fa-spin');
            }
            // re-enable button
            $(btn).removeAttr('disabled');
        }, 250);
    });

});
