// jquery
import 'jquery';

// modernizr
//import 'browsernizr';

// bootstrap
import 'bootstrap-sass/assets/javascripts/bootstrap/affix';
// import 'bootstrap-sass/assets/javascripts/bootstrap/alert';
import 'bootstrap-sass/assets/javascripts/bootstrap/button';
// import 'bootstrap-sass/assets/javascripts/bootstrap/carousel';
import 'bootstrap-sass/assets/javascripts/bootstrap/collapse';
import 'bootstrap-sass/assets/javascripts/bootstrap/dropdown';
import 'bootstrap-sass/assets/javascripts/bootstrap/tab';
import 'bootstrap-sass/assets/javascripts/bootstrap/transition';
// import 'bootstrap-sass/assets/javascripts/bootstrap/scrollspy';
// import 'bootstrap-sass/assets/javascripts/bootstrap/modal';
// import 'bootstrap-sass/assets/javascripts/bootstrap/tooltip';
// import 'bootstrap-sass/assets/javascripts/bootstrap/popover';

// blueimp-gallery
import 'expose-loader?blueimp.Gallery!blueimp-gallery/js/blueimp-gallery';
import "blueimp-gallery/css/blueimp-gallery.css"

// jquery.scrollup
import 'scrollup/dist/jquery.scrollUp';

// cc libs
import '../lib/cc/jquery.bsClasses';
import '../lib/cc/jquery.affixNavbar';

// app:
import './app/base';
import './app/theme.scss';