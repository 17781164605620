// affixNavbar
//
// attaches navbar to top of screen when scrolling down on page

$.fn.affixNavbar = function () {
    var $ = jQuery;
    var pluginName = 'affixNavbar',
        args = Array.prototype.slice.call(arguments),
        element = $(this),
        plugin = null,
        fn = null
        ;
    var affixClassName = 'navbar-fixed';
    var offset = element[0].offsetTop;

    function Plugin() {

        this.update = function () {
            $('body')[offset >= $(document).scrollTop() ? "removeClass" : "addClass"](affixClassName);

            // HACK: fix overlapping dropdown menus for screen-large and screen-desktop resolutions
            var dropdownMenus = $('.navbar-nav > .dropdown > .dropdown-menu', element);
            if ($('html').hasClass('screen-large') || $('html').hasClass('screen-desktop')) {
                $(dropdownMenus).css({
                    maxHeight: $(window).height() - 50,
                    overflow: 'auto'
                });
            } else {
                $(dropdownMenus).css({
                    maxHeight: '',
                    overflow: ''
                });
            }
        };

        this.activate = function () {
            $(document).bind('ready scroll', this.update);
            $(window).bind('resize', this.update);
            this.update();
        };

        this.deactivate = function () {
            $(document).unbind('ready scroll', this.update);
            $(window).unbind('resize', this.update);
        };

        this.activate();
    }

    // if there already is an instance for this element, try to call functions on the instance and bail out.
    if (element.data(pluginName)) {
        plugin = element.data(pluginName);
        fn = args.shift();
        if (plugin[fn] instanceof Function) {
            plugin[fn].apply(plugin, args);
        }
        else {
            window.console.warn('no such method', fn);
        }
        return plugin;
    }

    // otherwise, create a new instance and return it
    plugin = new Plugin(element);
    element.data(pluginName, plugin);
    return plugin;
};