// bsClasses
//
// render current bootstrap screen size as body class:
//
// @see: http://stackoverflow.com/questions/13029904/twitter-bootstrap-add-class-to-body-referring-to-its-mode/20402721#20402721

$.fn.bsClasses = function () {

    var $ = jQuery;
    var pluginName = 'bsClasses',
        args = Array.prototype.slice.call(arguments),
        element = $(this),

    // these are the "modes" we check for.
        modes = [
            {className: 'screen-phone', element: $('<span class="visible-xs"></span>')},
            {className: 'screen-tablet', element: $('<span class="visible-sm"></span>')},
            {className: 'screen-desktop', element: $('<span class="visible-md"></span>')},
            {className: 'screen-large', element: $('<span class="visible-lg"></span>')}
        ],
        _isMobile = false,
        plugin = null,
        fn = null
        ;

    function Plugin() {

        this.update = function () {
            $.each(modes, function (i) {
                if (modes[i].element.is(':visible')) {
                    element.addClass(modes[i].className);
                    _isMobile = modes[i].className == 'screen-phone' || modes[i].className == 'screen-tablet';
                } else {
                    element.removeClass(modes[i].className);
                }
            });
        };

        this.activate = function () {
            $(window).bind('resize.' + pluginName, this.update);
            $.each(modes, function (i) {
                element.append(modes[i].element);
            });
            this.update();
        };

        this.deactivate = function () {
            $(window).unbind('resize.' + pluginName);
            $.each(modes, function (i) {
                element.removeClass(modes[i].className);
                modes[i].element.remove();
            });
        };

        this.isDesktop = function() {
            return !_isMobile;
        };

        this.isMobile = function() {
            return _isMobile;
        };

        this.activate();
    }

    // if there already is an instance for this element, try to call functions on the instance and bail out.
    if (element.data(pluginName)) {
        plugin = element.data(pluginName);
        fn = args.shift();
        if (plugin[fn] instanceof Function) {
            plugin[fn].apply(plugin, args);
        }
        else {
            window.console.warn('no such method', fn);
        }
        return plugin;
    }

    // otherwise, create a new instance and return it
    plugin = new Plugin(element);
    element.data(pluginName, plugin);
    return plugin;
};